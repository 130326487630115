var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "alarmDetails", staticClass: "alarm-details" },
    [
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "search",
            {
              attrs: {
                alarmSourceList: _vm.alarmSourceList,
                alarmOptions: _vm.alarmOptions,
                affirmAlarmProps: _vm.affirmAlarmProps
              },
              on: { getFormVal: _vm.onSubmit }
            },
            [
              _vm.$store.state.menu.nowMenuList.indexOf("导出") >= 0
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        disabled: _vm.tableData.length === 0,
                        loading: _vm.downLoadStatus
                      },
                      on: { click: _vm.exportData }
                    },
                    [_vm._v("导出")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight } },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmType",
              label: "报警类型",
              width: "200",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.alarmAllObj[scope.row.alarmType]))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "alarmLevel", label: "报警等级" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dictObj.alarmLevelObj[Number(scope.row.alarmLevel)]
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cph",
              label: "车牌号",
              width: "120",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属车队",
              width: "160",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "driverName",
              label: "驾驶员姓名",
              width: "120",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "alarmSource", label: "报警来源" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dictObj.alarmSourceObj[
                          Number(scope.row.alarmSource)
                        ]
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmSignTime",
              label: "上报报警时间",
              "show-overflow-tooltip": "",
              width: "170"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              label: "上报报警经纬度",
              "show-overflow-tooltip": "",
              width: "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(scope.row.longitude) +
                        "," +
                        _vm._s(scope.row.latitude)
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "velocity", label: "报警时车速", width: "90" }
          }),
          _c("el-table-column", {
            attrs: { prop: "recordSpeed", label: "脉冲速度" }
          }),
          _c("el-table-column", {
            attrs: { prop: "limitValue", label: "限速值" }
          }),
          _c("el-table-column", {
            attrs: { prop: "positionStatus", label: "是否定位" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        scope.row.positionStatus === 0 ? "已定位" : "未定位"
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "vehStatus",
              label: "车辆状态",
              "show-overflow-tooltip": "",
              width: "210"
            }
          }),
          _c("el-table-column", { attrs: { prop: "runMile", label: "里程数" } })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("span", [_vm._v("共 " + _vm._s(_vm.total) + " 条")]),
          _c(
            "el-button",
            {
              attrs: {
                size: "small",
                type: "primary",
                loading: _vm.loading,
                disabled: !_vm.pageTokenList.length
              },
              on: { click: _vm.prePage }
            },
            [_vm._v("上一页")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "small",
                type: "primary",
                disabled: !_vm.nextToken
              },
              on: { click: _vm.nextPage }
            },
            [_vm._v("下一页")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }