<template>
  <div class="alarm-details" ref="alarmDetails">
    <div class="form-area" ref="form">
      <search
        @getFormVal="onSubmit"
        :alarmSourceList="alarmSourceList"
        :alarmOptions="alarmOptions"
        :affirmAlarmProps="affirmAlarmProps"
      >
        <el-button
          size="small"
          type="primary"
          v-if="$store.state.menu.nowMenuList.indexOf('导出') >= 0"
          :disabled="tableData.length === 0"
          :loading="downLoadStatus"
          @click="exportData"
          >导出</el-button
        >
      </search>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="alarmType"
        label="报警类型"
        width="200"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ alarmAllObj[scope.row.alarmType] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="alarmLevel" label="报警等级">
        <template slot-scope="scope">{{
          dictObj.alarmLevelObj[Number(scope.row.alarmLevel)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属车队"
        width="160"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="driverName"
        label="驾驶员姓名"
        width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="alarmSource" label="报警来源">
        <template slot-scope="scope">{{
          dictObj.alarmSourceObj[Number(scope.row.alarmSource)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="alarmSignTime"
        label="上报报警时间"
        show-overflow-tooltip
        width="170"
      ></el-table-column>
      <el-table-column
        prop
        label="上报报警经纬度"
        show-overflow-tooltip
        width="180"
      >
        <template slot-scope="scope"
          >{{ scope.row.longitude }},{{ scope.row.latitude }}</template
        >
      </el-table-column>
      <el-table-column
        prop="velocity"
        label="报警时车速"
        width="90"
      ></el-table-column>
      <el-table-column prop="recordSpeed" label="脉冲速度"></el-table-column>
      <el-table-column prop="limitValue" label="限速值"></el-table-column>
      <el-table-column prop="positionStatus" label="是否定位">
        <template slot-scope="scope">{{
          scope.row.positionStatus === 0 ? '已定位' : '未定位'
        }}</template>
      </el-table-column>
      <el-table-column
        prop="vehStatus"
        label="车辆状态"
        show-overflow-tooltip
        width="210"
      >
      </el-table-column>
      <el-table-column prop="runMile" label="里程数"></el-table-column>
    </el-table>
    <div ref="pagination" class="pagination">
      <!-- <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination> -->
      <span>共 {{ total }} 条</span>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        :disabled="!pageTokenList.length"
        @click="prePage"
        >上一页</el-button
      >
      <el-button
        size="small"
        type="primary"
        :disabled="!nextToken"
        @click="nextPage"
        >下一页</el-button
      >
    </div>
  </div>
</template>
<script>
import search from "./components/search.vue";
import {
  getAlarmNameList,
  queryDictsByCodes,
  queryAlarmDetailByQuery
} from '@/api/lib/api.js'
import { addExcelExport } from '@/api/lib/refreshAlarm.js'
import {
  formatDict,
  getCurent
} from "@/common/utils/index";
export default {
  components: {
    search
  },
  data () {
    return {
      form: {
        pageSize: 10,
        currentPage: 1,
      },
      tableData: [],
      tableHeight: 0,
      total: 0,
      alarmSourceList: [],//报警来源
      alarmList: [],//
      alarmAllObj: {},
      alarmOptions: [],
      affirmAlarmProps: {
        value: 'value',
        label: 'label',
        children: 'children',
        multiple: true
      },
      downLoadStatus: false,
      exportForm: {},
      nextToken: null,//v3.0更改下一页参数
      pageTokenList: [],//暂存页面分页请求token的list
      loading: false,
    }
  },
  created () {
    this.getDicts();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },

  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.alarmDetails.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight = wholeHeight - 16 - formHeight - paginationHeight - 10;
    },
    onSubmit (value) {
      this.form = { ...this.form, ...value }
      this.form.currentPage = 1;
      this.nextToken = null;
      this.getDataList();
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page;
      this.getDataList();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size;
      this.getDataList();
    },
    //下一页
    nextPage () {
      //点击下一页把当前页数据存进数组
      this.pageTokenList.push(this.nextToken);
      this.getDataList();
    },
    //上一页、
    prePage () {
      //点击上一页，把数组最新的数据移除掉后，取最新数据就是上一页数据
      this.pageTokenList.pop();
      this.nextToken = this.pageTokenList[this.pageTokenList.length - 1];
      this.getDataList();
    },
    getDataList () {
      let data = { ...this.form };
      // 多维数组转成一维数组，并去掉一级报警类型
      if (data.alarmType.length >= 1) {
        var newAlarmType = []
        var temAlarmType = [...new Set([...this.form.alarmType.flat(Infinity)])]
        temAlarmType.forEach((item) => {
          if (item !== 'D0008' && item !== 'D0009' && item !== 'D0010') {
            newAlarmType.push(item)
          }
        })
        data.alarmType = newAlarmType
      }
      data.nextToken = this.nextToken;
      this.loading = true;
      queryAlarmDetailByQuery(data).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data.list || []
          this.total = res.data.total || 0;
          this.nextToken = res.data.nextToken;
          //获取列表总页数-修复十倍数最后一页没有数据问题
          if (this.total <= (this.pageTokenList.length + 1) * 10) {
            this.nextToken = null;
          }
        } else {
          this.tableData = []
          this.total = 0
          this.nextToken = null;
          this.pageTokenList = [];
        }
        this.loading = false;
        this.exportForm = { ...data }
      })
    },
    //导出
    exportData () {
      this.downLoadStatus = true
      delete this.exportForm.currentPage
      delete this.exportForm.pageSize
      let data = {
        baseUrl: 'base',
        fileName: `报警明细信息-${getCurent()}`,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: '/alarm/alarmDetailByQueryExport'
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({
        parentCodes:
          'BJLY,BJZT,BJZL,BJDJ,D0008,D0009,D0010'
      }).then((res) => {
        if (res.code === 1000) {
          res.data.BJZL.splice(2, 1) //删除终端故障报警配置
          this.alarmSourceList = res.data.BJLY;
          this.alarmList = res.data.BJZL;
          this.dictObj = {
            alarmSourceObj: formatDict(res.data.BJLY),
            alarmStatusObj: formatDict(res.data.BJZT),
            alarmLevelObj: formatDict(res.data.BJDJ),
            D0008Obj: formatDict(res.data.D0008),
            D0009Obj: formatDict(res.data.D0009),
            D0010Obj: formatDict(res.data.D0010),
            typeObj: formatDict(
              [].concat(res.data.D0008, res.data.D0009, res.data.D0010)
            )
          }
        }
      }).then(() => {
        this.getAlarmTypeFn();
      })
    },
    // 获取报警类型
    getAlarmTypeFn () {
      const self = this
      self.alarmOptions = []
      self.alarmAllOptions = []
      self.alarmList.forEach((item) => {
        self.alarmOptions.push({
          label: item.dictValue,
          value: item.dictCode,
          children: []
        })
      })
      getAlarmNameList({ alarmSpecies: 'D0008,D0009', system: sessionStorage.getItem('system').toString() }).then(
        (res) => {
          if (res.code === 1000) {
            res.data.forEach((item, index) => {
              if (item.alarmSpecies === 'D0008') {
                self.alarmOptions[0].children.push({
                  label: self.dictObj.D0008Obj[item.alarmType],
                  value: item.alarmType
                })
                self.alarmAllOptions.push({
                  dictValue: self.dictObj.D0008Obj[item.alarmType],
                  dictCode: item.alarmType
                })
              } else if (item.alarmSpecies === 'D0009') {
                self.alarmOptions[1].children.push({
                  label: self.dictObj.D0009Obj[item.alarmType],
                  value: item.alarmType
                })
                self.alarmAllOptions.push({
                  dictValue: self.dictObj.D0009Obj[item.alarmType],
                  dictCode: item.alarmType
                })
              }
            })
            self.alarmAllObj = formatDict(self.alarmAllOptions)
          }
        }
      )
    },
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
}
</script>
<style lang="scss" scoped>
.alarm-details {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
.pagination {
  margin: 16px 16px;
  text-align: right;
  span {
    margin-right: 24px;
  }
}
</style>