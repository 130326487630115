<template>
  <div class="searchForm">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      class="demo-form-inline"
      label-position="left"
      label-width="86px"
    >
      <el-form-item label="车牌号：" prop="vehicleNos">
        <car-tree @getData="getVehIds" ref="carTree"></car-tree>
      </el-form-item>
      <!-- 选择时间： -->
      <el-form-item label="开始时间：" prop="beginTime">
        <el-date-picker
          v-model="form.beginTime"
          type="datetime"
          placeholder="选择开始时间"
          :clearable="false"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="结束时间：" prop="endTime">
        <el-date-picker
          v-model="form.endTime"
          type="datetime"
          placeholder="选择结束时间"
          :clearable="false"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="报警类型：" prop="alarmType">
        <el-cascader
          v-model="form.alarmType"
          :options="alarmOptions"
          :props="affirmAlarmProps"
          size="small"
          collapse-tags
          clearable
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="报警来源：" prop="alarmSource">
        <el-select clearable v-model="form.alarmSource" placeholder="请选择">
          <el-option
            v-for="item in alarmSourceList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label-width="0px">
        <el-button size="small" type="primary" @click="search">查询</el-button>
        <slot />
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  formatDate,
  getStartDate,
  checkTimeLimitDay,
  getCurentDate,
  checkTimeLimit,
  checkLimitDate
} from "@/common/utils/index";

import carTree from '@/components/carTree/carSearchTree.vue'
export default {
  components: {
    carTree
  },
  props: {
    //能否选择今天
    chooseToday: {
      type: Boolean,
      default: false
    },
    //报警来源
    alarmSourceList: {
      type: Array,
      default: () => []
    },
    //报警类型选项
    alarmOptions: {
      type: Array,
      default: () => []
    },
    //报警类型选项
    affirmAlarmProps: {
      type: Object,
      default: () => ({
        value: 'value',
        label: 'label',
        children: 'children',
        multiple: true
      })
    }
  },
  data () {
    // 不能超前选择
    const validateDateForward = (rule, value, callback) => {
      if (this.chooseToday) {
        if (
          new Date() <= value[0] ||
          new Date() <= value[1]
        ) {
          callback(new Error('不能选择未来时间'))
        } else {
          callback();
        }
      } else {
        if (new Date(getStartDate()) <= value[0] || new Date(getStartDate()) <= value[1]) {
          callback(new Error("不能选择今天及未来时间"));
        } else {
          callback();
        }
      }
    };
    // 详细时间段
    let validateDateTime1 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(value, this.form.endTime)) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      if (value != null && !checkTimeLimitDay(value, this.form.endTime)) {
        callback(new Error('只能查询31天内的数据'))
      } else {
        callback()
      }
    }
    let validateDateTime2 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(this.form.beginTime, value)) {
        callback(new Error('结束时间必须大于开始时间'))
      }
      if (value != null && !checkTimeLimitDay(this.form.beginTime, value)) {
        callback(new Error('只能查询31天内的数据'))
      } else {
        callback()
      }
    }
    return {
      form: {
        vehicleNos: [],//车架号
        beginTime:new Date(getStartDate()), // 开始时间
        endTime: new Date(getCurentDate()), // 结束时间
        alarmType: [], // 报警类型
        alarmSource: '', // 报警来源
      },
      formRules: {
        alarmSource: [
          { required: true, message: "请选择报警来源", trigger: "blur" }
        ],
        alarmType:[
          { required: true, message: "请选择报警类型", trigger: "blur" }
        ],
        vehicleNos:[
          { required: true, message: "请选择车辆", trigger: "blur" }
        ],
        beginTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime1, trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime2, trigger: 'blur' }
        ],
      },
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
    }
  },

  methods: {
    // 获取车辆数据
    getVehIds (val) {
      this.form.vehicleNos = [...val];
    },
    search () {
      this.$refs.form.validate(valid => {
        if (valid) {  
          let data = {...this.form };
          data.beginTime = formatDate(this.form.beginTime)
          data.endTime = formatDate(this.form.endTime)
          this.$emit("getFormVal", data);
        }
      })
    },

  }
}
</script>
<style lang="scss" scoped>
</style>
